import React, { useMemo, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useStoreon } from "storeon/react";

import { getGoalRepeatText } from "services/common";
import { setGoalResponse, sendNotification } from "services/database";
import { datesDiffInDays } from "services/utilities";

import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import Divider from "@material-ui/core/Divider";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import HelpIcon from "@material-ui/icons/Help";

import ImageUpload from "modules/components/ImageUpload";
import Comments from "modules/components/Comments";

/* Happy Emoji, Sad, Heart, Thumbs Up. Question mark */
const actions = [
  { icon: <SentimentVerySatisfiedIcon />, name: ":)" },
  { icon: <SentimentVeryDissatisfiedIcon />, name: ":(" },
  { icon: <ThumbUpIcon />, name: "(y)" },
  { icon: <HelpIcon />, name: "?" },
  { icon: <FavoriteIcon />, name: "<3" },
];

const BorderLinearProgress = withStyles({
  root: {
    height: 15,
    backgroundColor: "#D8D8D8",
    borderRadius: 20,
  },
  bar: {
    borderRadius: 20,
    backgroundColor: "#5BAAB9",
  },
})(LinearProgress);

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    overflow: "visible",
  },
  flexWrap: {
    flexWrap: "wrap",
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    border: "none",
  },
  marginTopBottom1: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  marginTopBottom2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  actionIcon: {
    margin: 5,
    flexGrow: 1,
  },
  noPadTop: {
    paddingTop: 0,
  },
  actionButton: {
    margin: "0 1em",
    flexGrow: 1,
  },
  btnSuccess: {
    backgroundColor: "#8ddb8d",
  },
  btnDanger: {
    backgroundColor: "#f87e7e",
  },
  responseArea: {
    width: "100%",
    marginTop: 20,
    textAlign: "center",
  },
}));

export default ({ goal, onAnswered, askResponses, isToday }) => {
  const classes = useStyles();
  const { dispatch, auth } = useStoreon("auth");
  const [menuEl, setMenuEl] = useState(null);
  const menuOpen = Boolean(menuEl);
  const [filename, setFilename] = useState(null);

  const completion = useMemo(() => {
    const durationInDays = datesDiffInDays(
      goal.resolution.start,
      goal.resolution.end
    );
    const daysSoFar = datesDiffInDays(goal.resolution.start, new Date());
    return parseInt((daysSoFar * 100) / durationInDays);
  }, [goal.resolution.start, goal.resolution.end]);

  const sendMessage = (code) => {
    setMenuEl(null);
    const text = `${goal.title}: ${code}`;
    sendNotification(
      auth.user.userID,
      `${auth.user.firstname} sent you a message`,
      text,
      goal.user
    );
  };

  const enterGoalResponse = (successes, fails) => {
    dispatch("ui/setLoading", true);
    // TODO: add warning /confirm dialog
    const updateData = {
      userID: auth.user.userID,
      resolutionID: goal.resolution.id,
      goalID: goal.id,
      successes,
      fails,
      filename,
    };
    setGoalResponse(updateData)
      .then((updatedGoal) => {
        dispatch("ui/setLoading", false);
        onAnswered(goal.id);
      })
      .catch((error) => {
        dispatch("ui/setLoading", false);
        dispatch("error/setError", {
          visible: true,
          text: `${error.message} (${error.code})`,
        });
      });
  };

  /*const getGoalDate = useMemo(() => {
    const now = new Date();
    if (isToday) {
      return `${now.getFullYear()}${now.getMonth()}${now.getDate()}`;
    }
  }, [isToday]);*/

  return (
    <Card className={classes.card} elevation={4}>
      <CardHeader
        avatar={
          <Avatar
            className={classes.avatar}
            src={goal.user.blob || goal.user.pic}
          />
        }
        action={
          <React.Fragment>
            <IconButton
              aria-label="reactions"
              aria-controls="reaction-menu"
              onClick={(e) => setMenuEl(e.currentTarget)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="reaction-menu"
              anchorEl={menuEl}
              keepMounted
              open={menuOpen}
              onClose={() => setMenuEl(null)}
            >
              {actions.map((action) => (
                <MenuItem
                  key={action.name}
                  onClick={() => sendMessage(action.name)}
                >
                  <ListItemIcon>{action.icon}</ListItemIcon>
                </MenuItem>
              ))}
            </Menu>
          </React.Fragment>
        }
        title={goal.user.firstname}
        titleTypographyProps={{ color: "secondary", variant: "h6" }}
        subheader={goal.title}
        subheaderTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
      />
      <CardContent className={classes.noPadTop}>
        <Typography variant="caption">{getGoalRepeatText(goal)}</Typography>
        <Typography variant="body1">{goal.description}</Typography>
        <Divider className={classes.marginTopBottom2} />
        <Typography variant="subtitle2">
          {goal.resolution.title} ({completion}%)
        </Typography>
        <BorderLinearProgress
          className={classes.marginTopBottom1}
          variant="determinate"
          color="secondary"
          value={completion}
        />
      </CardContent>
      <CardActions disableSpacing className={classes.flexWrap}>
        {askResponses && goal.user.userID === auth.user.userID && (
          <div className={classes.responseArea}>
            <div>Have you completed this today?</div>
            <Button
              className={`${classes.actionButton} ${classes.btnSuccess}`}
              onClick={() => enterGoalResponse(1, 0)}
              variant="contained"
            >
              Yes
            </Button>
            <Button
              className={`${classes.actionButton} ${classes.btnDanger}`}
              onClick={() => enterGoalResponse(0, 1)}
              variant="contained"
            >
              No
            </Button>
            <div>
              <ImageUpload returnFilename={(fname) => setFilename(fname)} />
            </div>
          </div>
        )}
        <div style={{ width: "100%" }}>
          <Comments chatId={goal.id}></Comments>
        </div>
      </CardActions>
    </Card>
  );
};
