import React, { useCallback, useEffect } from "react";
import { useStoreon } from "storeon/react";
import * as serviceWorker from "serviceWorker";
import { useSnackbar } from "notistack";

import { AuthCheck, AuthCheckRedirect } from "services/database";
import { FirebaseMessaging } from "services/firebase";
import { userPostLogin } from "services/common";

import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import Avatar from "@material-ui/core/Avatar";

import Auth from "modules/Auth";
import Main from "modules/Main/Main";
import GroupSelect from "modules/Group/GroupSelect";
import GroupCreate from "modules/Group/GroupCreate";
import ResolutionCreate from "modules/Resolution/ResolutionCreate";

import "./Normalize.css";
import "./App.css";

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: { main: "#ffee6f" },
    secondary: { main: "#5BAAB9" },
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    /*text: {
      primary: "#ffffff",
      secondary: "#eeeeee",
      disabled: "#aaaaaa",
      hint: "#effefe",
    }*/
  },
  typography: {
    fontSize: 10,
  },
});

export default () => {
  const { dispatch, ui, error, auth } = useStoreon("ui", "error", "auth");
  const { enqueueSnackbar } = useSnackbar();

  const checkAuthStatus = useCallback(async () => {
    dispatch("ui/setLoading", true);
    const user = await AuthCheck();
    if (user && user.email) {
      userPostLogin(user, dispatch);
    } else {
      // Firebase redirect auth checks
      AuthCheckRedirect()
        .then((usr) => {
          console.log(usr);
          userPostLogin(usr, dispatch);
        })
        .catch((error) => {
          userPostLogin(null, dispatch);
          dispatch("error/setError", {
            visible: true,
            text: `${error.message} (${error.code})`,
          });
        });
    }
  }, [dispatch]);

  useEffect(() => {
    checkAuthStatus();
    window.history.pushState({}, "");
    console.log("loading App...");
  }, [checkAuthStatus]);

  useEffect(() => {
    FirebaseMessaging &&
      FirebaseMessaging.onMessage((payload) => {
        console.log("Message received. ", payload);
        const msg = payload.notification;
        const data = JSON.parse(payload.data["gcm.notification.data"]);
        const from = auth.group.members.find((u) => u.userID === data.uid);

        const action = (key) => (
          <Avatar
            src={from && (from.blob || from.pic)}
            title={from.firstname}
          />
        );

        enqueueSnackbar(msg.body, {
          variant: "info",
          action,
        });
      });
  }, [enqueueSnackbar, auth.group.members]);

  window.addEventListener("popstate", () => {
    dispatch("error/setError", {
      visible: true,
      text: "Press back again to exit...",
    });
    setTimeout(() => {
      window.history.pushState({}, "");
      dispatch("error/setError", { visible: false, text: "" });
    }, 3000);
  });

  // enable service workers
  if (process.env.NODE_ENV === "production") {
    serviceWorker.register({
      onUpdate: (installingWorker) => {
        console.log(installingWorker);
        dispatch("error/setError", {
          visible: true,
          text: "New version found... Please wait while reloading...",
        });
      },
    });
  }
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Backdrop open={ui.loading} style={{ zIndex: 9999 }}>
          <CircularProgress size={50} />
        </Backdrop>

        {ui.appScreen === "Auth" && <Auth />}
        {ui.appScreen === "Home" && <Main />}
        {ui.appScreen === "GroupSelect" && <GroupSelect />}
        {ui.appScreen === "GroupCreate" && <GroupCreate />}
        {ui.appScreen === "ResolutionCreate" && <ResolutionCreate />}

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={error.visible}
          onClose={() => dispatch("error/showError", false)}
          ContentProps={{ "aria-describedby": "message-id" }}
          message={<span id="message-id">{error.text}</span>}
        />
      </div>
    </MuiThemeProvider>
  );
};
