import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useStoreon } from "storeon/react";

import { getAllGroups, getGroup, addMemberToGroup } from "services/database";

import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Cached from "@material-ui/icons/Cached";
import Public from "@material-ui/icons/Public";
import Lock from "@material-ui/icons/Lock";
import Group from "@material-ui/icons/Group";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.main,
  },
  margin: {
    display: "block",
    margin: theme.spacing(2),
  },
  marginTopBottom: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  card: {
    padding: theme.spacing(2),
    width: "94%",
    margin: "20px auto",
    background: "#f1f1f1",
  },
  logo: {
    margin: "20px auto",
    maxWidth: 100,
  },
  groupCard: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    textAlign: "left",
  },
  headerIcon: {
    fontSize: "1.3em",
    verticalAlign: "middle",
    marginRight: theme.spacing(1),
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  padding1: {
    padding: theme.spacing(1),
  },
  headerSub: {
    fontSize: "0.8em",
  },
}));

export default () => {
  const classes = useStyles();
  const { dispatch, auth } = useStoreon("auth");
  const [refresh, setRefresh] = useState(0);
  const [groups, setGroups] = useState([]);
  const [passOpen, setPassOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    dispatch("ui/setLoading", true);
    getAllGroups().then((results) => {
      setGroups(results);
      dispatch("ui/setLoading", false);
    });
  }, [refresh, dispatch]);

  const clickJoin = (selected) => {
    dispatch("ui/setLoading", true);

    addMemberToGroup(selected.id, auth.user.userID, password)
      .then(async (added) => {
        const group = await getGroup(selected.id);
        dispatch("auth/setUser", { ...auth.user, groupID: selected.id });
        dispatch("ui/setLoading", false);
        dispatch("auth/setGroup", group);
        dispatch("ui/setScreen", "Home");
      })
      .catch((error) => {
        dispatch("ui/setLoading", false);
        dispatch("error/setError", {
          visible: true,
          text: `${error.message} (${error.code})`,
        });
      });
  };

  const groupCheck = (group) => {
    if (group.isPrivate) {
      setSelectedGroup(group);
      setPassOpen(true);
    } else {
      clickJoin(group);
    }
  };

  return (
    <Container maxWidth="md" className={classes.root}>
      <Paper elevation={3} className={classes.card}>
        <img
          src={process.env.PUBLIC_URL + "/logo512.png"}
          alt="Akitu"
          className={classes.logo}
        />
        <Typography variant="h4" component="h1">
          Welcome!
        </Typography>
        <Typography variant="h5" component="h1">
          Select an existing group to join or create a new one.
          <IconButton
            aria-label="refresh"
            color="secondary"
            onClick={() => setRefresh((r) => r + 1)}
          >
            <Cached />
          </IconButton>
        </Typography>
        <div className={classes.marginTopBottom}>
          {groups &&
            groups.map((group) => (
              <Card key={group.id} className={classes.groupCard}>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {group.name}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.headerSub}
                    color="textSecondary"
                  >
                    {!group.isPrivate && (
                      <Public className={classes.headerIcon} />
                    )}
                    {group.isPrivate && <Lock className={classes.headerIcon} />}
                    {group.isPrivate ? "Private" : "Public"}
                    <span className={classes.padding1}>|</span>
                    <Group className={classes.headerIcon} />
                    {group.members.length}/{group.max}
                    <span className={classes.padding1}>|</span>
                    {group.category}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.mt2}
                  >
                    {group.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    disabled={group.members.length >= group.max}
                    onClick={() => groupCheck(group)}
                  >
                    Join Group
                  </Button>
                </CardActions>
              </Card>
            ))}
        </div>
        <hr className={classes.margin} />
        <Button
          variant="contained"
          color="primary"
          onClick={() => dispatch("ui/setScreen", "GroupCreate")}
        >
          Create a new Group
        </Button>
      </Paper>
      <Dialog
        open={passOpen}
        onClose={() => setPassOpen(false)}
        aria-labelledby="form-dialog-password"
      >
        <DialogTitle id="form-dialog-title">Enter Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This Group is Private. Enter the group password to join.
          </DialogContentText>
          <TextField
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoFocus
            margin="dense"
            label="Group Password"
            fullWidth
            color="secondary"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPassOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => clickJoin(selectedGroup)} color="secondary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
