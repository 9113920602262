import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useStoreon } from "storeon/react";

import { AuthSocial } from "services/database";

import { userPostLogin } from "services/common";

import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.main,
  },
  margin: {
    display: "block",
    margin: theme.spacing(2),
  },
  marginTopBottom: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  loginArea: {
    padding: theme.spacing(2),
    minWidth: 400,
    maxWidth: "100%",
  },
  logo: {
    margin: "20px auto",
    maxWidth: 100,
  },
}));

export default () => {
  const classes = useStyles();
  const { dispatch } = useStoreon();

  const loginSocial = (provider) => {
    dispatch("ui/setLoading", true);
    AuthSocial(provider)
      .then((user) => {
        userPostLogin(user, dispatch);
      })
      .catch((error) => {
        dispatch("ui/setLoading", false);
        console.log(error);
        dispatch("error/setError", {
          visible: true,
          text: `${error.message} (${error.code})`,
        });
      });
  };

  return (
    <Container maxWidth="md" className={classes.root}>
      <Paper elevation={3} className={classes.loginArea}>
        <img
          src={process.env.PUBLIC_URL + "/logo512.png"}
          alt="Akitu"
          className={classes.logo}
        />
        <Typography variant="h5" component="h1">
          Welcome to Akitu
        </Typography>

        <Button
          className={classes.marginTopBottom}
          variant="contained"
          color="primary"
          onClick={() => loginSocial("google")}
        >
          Login With Google
        </Button>
        <Divider variant="middle" />
        <Button
          className={classes.marginTopBottom}
          variant="contained"
          color="primary"
          onClick={() => loginSocial("facebook")}
        >
          Login With Facebook
        </Button>
        <p>Version: {process.env.REACT_APP_VERSION}</p>
      </Paper>
    </Container>
  );
};
