const isJson = (item) => {
  let it = typeof item !== "string" ? JSON.stringify(item) : item;

  try {
    it = JSON.parse(it);
  } catch (e) {
    return false;
  }

  if (typeof it === "object" && it !== null) {
    return true;
  }

  return false;
};

var copyWithout = (obj, attrs) => {
  const newObj = JSON.parse(JSON.stringify(obj));
  attrs.forEach((a) => {
    delete newObj[a];
  });

  return newObj;
};

const camelCase = (str) => {
  const st = str
    .replace(/-/g, " ") // convert all hyphens to spaces
    .replace(/\s[a-z]/g, str.toLowerCase()) // convert first char of each word to UPPERCASE
    .replace(/\s+/g, "") // remove spaces
    .replace(/^[A-Z]/g, str.toUpperCase()); // convert first char to lowercase
  return st;
};

const isSameDate = (someDate, otherDate) => {
  someDate = getDateFromAll(someDate);
  otherDate = getDateFromAll(otherDate);
  return (
    someDate.getDate() === otherDate.getDate() &&
    someDate.getMonth() === otherDate.getMonth() &&
    someDate.getFullYear() === otherDate.getFullYear()
  );
};

const isWithinDays = (someDate, days) => {
  someDate = getDateFromAll(someDate);
  const today = new Date();

  return datesDiffInDays(someDate, today) <= days;
};

const datesDiffInDays = (dateFrom, dateTo) => {
  const daySeconds = 86400000;
  dateFrom = getDateFromAll(dateFrom);
  dateTo = getDateFromAll(dateTo);
  dateFrom.setHours(0, 0, 0, 0);
  dateTo.setHours(0, 0, 0, 0);

  return Math.round((dateTo - dateFrom) / daySeconds);
};

const randFromTo = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const dayOfWeekAsString = (dayIndex) =>
  [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ][dayIndex];

const copyToClipboard = (value) => {
  const tempInput = document.createElement("textarea");
  tempInput.value = value;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand("copy");
  document.body.removeChild(tempInput);
};

const isRunningStandalone = () =>
  !!navigator.standalone ||
  window.matchMedia("(display-mode: standalone)").matches;

var extend = function (out) {
  out = out || {};
  for (var i = 1; i < arguments.length; i++) {
    if (!arguments[i]) continue;

    for (var key in arguments[i]) {
      if (arguments[i].hasOwnProperty(key)) {
        if (JSON.stringify(out[key]) !== JSON.stringify(arguments[i][key])) {
          out[key] = arguments[i][key];
        }
      }
    }
  }
  return out;
};

const getResponsesCounts = (goal, resolution) => {
  const now = new Date();
  goal.responseDays = goal.responseDays || 0;
  goal.responseSuccess = goal.responseSuccess || 0;

  let goalDays = 0;
  switch (goal.target) {
    case "once":
      goalDays =
        datesDiffInDays(now, getDateFromAll(goal.targetDate)) < 0 ? 1 : 0;
      break;
    case "daily":
      goalDays = datesDiffInDays(resolution.start, now);
      break;
    case "weekly":
      const daysPastStart = datesDiffInDays(resolution.start, now);
      const startDate = new Date(resolution.start);
      for (let d = 0; d <= daysPastStart; d++) {
        if (goal.weekdays.includes(startDate.getDay())) {
          goalDays = goalDays + 1;
        }
        startDate.setDate(startDate.getDate() + 1);
      }
      break;
    default:
      goalDays = 0;
  }

  return { should: goalDays, waiting: goalDays - goal.responseDays };
};

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

const getDateFromAll = (dateAny) => {
  if (typeof dateAny === "string" || dateAny instanceof String) {
    return new Date(dateAny);
  } else if (dateAny instanceof Date) {
    return dateAny;
  } else if (dateAny.seconds) {
    return dateAny.toDate();
  } else {
    return dateAny;
  }
};

export {
  isJson,
  camelCase,
  randFromTo,
  copyWithout,
  isSameDate,
  isWithinDays,
  dayOfWeekAsString,
  datesDiffInDays,
  copyToClipboard,
  isRunningStandalone,
  extend,
  getResponsesCounts,
  asyncForEach,
  getDateFromAll,
};
