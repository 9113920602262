import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useStoreon } from "storeon/react";

import { useInputValue } from "services/hooks";
import { createGroup, updateUser } from "services/database";

import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import Slider from "@material-ui/core/Slider";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.main,
  },
  margin: {
    display: "block",
    margin: theme.spacing(2),
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  marginTopBottom: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  card: {
    padding: theme.spacing(2),
    width: "94%",
    margin: "20px auto",
  },
  logo: {
    margin: "20px auto",
    maxWidth: 100,
  },
  togglePass: {
    display: "flex",
    justifyContent: "start",
  },
}));

export default () => {
  const classes = useStyles();
  const { dispatch, auth } = useStoreon("auth");
  const inputLabel = useRef(null);
  const { ...groupName } = useInputValue("");
  const { ...groupCategory } = useInputValue("");
  const { ...groupPass } = useInputValue("");
  const { ...groupDescription } = useInputValue("");
  const [groupMax, setGroupMax] = React.useState(4);
  const [isPrivate, setIsPrivate] = useState(false);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const createSubmit = (e) => {
    e.preventDefault();
    const name = groupName.value.trim();
    const category = groupCategory.value.trim();
    const password = groupPass.value.trim();
    const description = groupDescription.value.trim();
    if (!name || !category || (!password && isPrivate)) {
      dispatch("error/setError", {
        visible: true,
        text: `Please, fill in all the fields.`,
      });
      return false;
    }
    const newGroup = {
      name,
      category,
      password,
      description,
      isPrivate,
      max: groupMax,
      members: [auth.user.userID],
    };
    dispatch("ui/setLoading", true);
    createGroup(newGroup)
      .then(async (created) => {
        await updateUser(auth.user.userID, { groupID: created.id });
        dispatch("auth/setUser", { ...auth.user, groupID: created.id });
        dispatch("ui/setLoading", false);
        dispatch("auth/setGroup", created);
        dispatch("ui/setScreen", "Home");
      })
      .catch((error) => {
        dispatch("ui/setLoading", false);
        console.log(error);
        dispatch("error/setError", {
          visible: true,
          text: `${error.message} (${error.code})`,
        });
      });
  };

  return (
    <Container maxWidth="md" className={classes.root}>
      <Paper elevation={3} className={classes.card}>
        <img
          src={process.env.PUBLIC_URL + "/logo512.png"}
          alt="Akitu"
          className={classes.logo}
        />
        <Typography variant="h4" component="h1">
          Create a new group
        </Typography>
        <form
          className={classes.marginTopBottom}
          noValidate
          onSubmit={createSubmit}
        >
          <TextField
            {...groupName}
            className={classes.marginTopBottom}
            id="input-group-name"
            variant="outlined"
            autoFocus={true}
            fullWidth
            label="Group Name"
            color="secondary"
          />
          <TextField
            {...groupDescription}
            className={classes.marginTopBottom}
            id="input-group-description"
            variant="outlined"
            fullWidth
            label="Group Description"
            color="secondary"
            multiline={true}
          />

          <FormControl
            variant="outlined"
            className={classes.marginTopBottom}
            fullWidth
          >
            <InputLabel
              ref={inputLabel}
              id="select-group-category-label"
              color="secondary"
            >
              Group Category
            </InputLabel>
            <Select
              {...groupCategory}
              labelId="select-group-category-label"
              id="select-group-category"
              color="secondary"
              labelWidth={labelWidth}
            >
              <MenuItem value="Health">Health</MenuItem>
              <MenuItem value="Wellness">Wellness</MenuItem>
              <MenuItem value="Workout">Workout</MenuItem>
              <MenuItem value="Study">Study</MenuItem>
            </Select>
          </FormControl>

          <div className={`${classes.marginTopBottom} ${classes.togglePass}`}>
            <FormControlLabel
              label="Private"
              control={
                <Switch
                  checked={isPrivate}
                  onChange={() => setIsPrivate((p) => !p)}
                  value="private"
                />
              }
            />
            {isPrivate && (
              <TextField
                {...groupPass}
                id="input-group-pass"
                variant="outlined"
                label="Group Password"
                color="secondary"
              />
            )}
          </div>

          <Typography gutterBottom>Maximum Members: {groupMax}</Typography>
          <Slider
            className={classes.marginTopBottom}
            defaultValue={4}
            step={1}
            min={2}
            max={8}
            onChangeCommitted={(e, val) => setGroupMax(val)}
            valueLabelDisplay="auto"
            aria-labelledby="slider-group-max"
            color="secondary"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => dispatch("ui/setScreen", "GroupSelect")}
            className={classes.marginRight}
          >
            Back
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Create Group
          </Button>
        </form>
      </Paper>
    </Container>
  );
};
