import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useStoreon } from "storeon/react";

import {
  AuthLogout,
  removeMemberFromGroup,
  askForPermissioToReceiveNotifications,
  deleteResolution,
} from "services/database";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import { Button } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";

import MoreIcon from "@material-ui/icons/MoreVert";
import Public from "@material-ui/icons/Public";
import Lock from "@material-ui/icons/Lock";
import Group from "@material-ui/icons/Group";

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const HideOnScroll = (props) => {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(5),
    flexWrap: "wrap",
  },
  headerTop: {
    width: "100%",
    textAlign: "left",
  },
  headerBottom: {
    width: "100%",
    display: "flex",
    position: "relative",
    alignItems: "center",
  },
  headerSub: {
    fontSize: "0.8em",
    color: "#746e45",
    textAlign: "left",
  },
  headerPics: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
  },
  headerIcon: {
    fontSize: "1.3em",
    verticalAlign: "middle",
    marginRight: theme.spacing(1),
  },
  avatarGroup: {
    "& span": {
      borderRadius: "50%",
    },
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    border: "none",
  },
  padding1: {
    padding: theme.spacing(1),
  },
  mt1: {
    marginTop: theme.spacing(1),
  },
}));

export default (props) => {
  const classes = useStyles();
  const { dispatch, auth } = useStoreon("auth");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const leaveGroup = () => {
    dispatch("ui/setLoading", true);
    removeMemberFromGroup(auth.user.groupID, auth.user.userID).then(
      (removed) => {
        window.location.reload();
      }
    );
  };

  const deleteCurrentResolution = async () => {
    if (
      window.confirm(
        "This will delete your current resolution and all goals and responses for it! You sure?"
      )
    ) {
      dispatch("ui/setLoading", true);
      await deleteResolution(auth.user.resolutions[0].id, auth.user.userID);
      window.location.reload();
    }
  };

  return (
    <HideOnScroll {...props}>
      <AppBar position="sticky">
        <Toolbar className={classes.header}>
          <div className={classes.headerTop}>
            <Typography variant="h4">{auth.group.name}</Typography>
            <Typography variant="subtitle1" className={classes.headerSub}>
              {!auth.group.isPrivate && (
                <Public className={classes.headerIcon} />
              )}
              {auth.group.isPrivate && <Lock className={classes.headerIcon} />}
              {auth.group.isPrivate ? "Private" : "Public"}
              <span className={classes.padding1}>|</span>
              <Group className={classes.headerIcon} />
              {auth.group.members.length}/{auth.group.max}
              <span className={classes.padding1}>|</span>
              {auth.group.category}
            </Typography>
          </div>
          <div className={classes.headerBottom}>
            <div className={classes.headerPics}>
              <AvatarGroup className={classes.avatarGroup}>
                {auth.group.members.map((user) => (
                  <Tooltip title={user.firstname || ""} key={user.userID}>
                    <StyledBadge
                      overlap="circle"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      variant="dot"
                    >
                      <Avatar
                        alt={user.firstname}
                        src={user.blob || user.pic}
                        className={classes.avatar}
                      />
                    </StyledBadge>
                  </Tooltip>
                ))}
              </AvatarGroup>
              {auth.user && auth.user.resolutions.length === 0 && (
                <div className={classes.mt1}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => dispatch("ui/setScreen", "ResolutionCreate")}
                  >
                    Create a Resolution
                  </Button>
                </div>
              )}
              {auth.user && auth.user.resolutions.length > 0 && (
                <div className={classes.mt1}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={deleteCurrentResolution}
                  >
                    Delete current Resolution
                  </Button>
                </div>
              )}
            </div>
            <div>
              <IconButton
                aria-label="Account Settings"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                color="inherit"
                edge="end"
              >
                <MoreIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={() => dispatch("ui/refreshData", true)}>
                  Refresh Data
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    askForPermissioToReceiveNotifications(auth.user.userID)
                  }
                >
                  Get Notifications
                </MenuItem>
                <MenuItem onClick={() => setAnchorEl(null)}>Profile</MenuItem>
                <MenuItem onClick={() => setAnchorEl(null)}>Account</MenuItem>
                <MenuItem onClick={() => leaveGroup()}>Leave Group</MenuItem>
                <MenuItem onClick={() => AuthLogout()}>Logout</MenuItem>
              </Menu>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};
