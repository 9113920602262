import Firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/storage";
import "firebase/messaging";
//import "firebase/analytics";

const config = {
  apiKey: "AIzaSyAQOyhdU3FcGBuhpPOTyaqT7u1ltL39U2A",
  authDomain: "akitu-demo.firebaseapp.com",
  databaseURL: "https://akitu-demo.firebaseio.com",
  projectId: "akitu-demo",
  storageBucket: "akitu-demo.appspot.com",
  messagingSenderId: "652695952216",
  appId: "1:652695952216:web:4d7c188c26b7af8407e32d",
  measurementId: "G-VP6LKW8LZM",
};

const FirebaseApp = Firebase.initializeApp(config);
// Firebase.analytics();

const FirebaseRealtime = Firebase.database();
const FirebaseAuth = FirebaseApp.auth();
const FirebaseDb = FirebaseApp.firestore(); // https://firebase.google.com/docs/firestore/manage-data/enable-offline

const FirebaseStorage = {
  init(uid) {
    this.storage = FirebaseApp.storage();
    this.ref = this.storage.ref();
    this.images = this.ref.child(`${uid}/images`);
  },
  images: null,
  storage: null,
  ref: null,
};
let FirebaseMessaging = null;
if (Firebase.messaging.isSupported()) {
  FirebaseMessaging = Firebase.messaging();
}
FirebaseMessaging.usePublicVapidKey(
  "BGSYd-Qeyubh55PqnvfnfLNJBFhIEmhIBgi_WBs0F8lZEGqfi2OydCHFm4TJrblNlxcPVr9MsBvGSSFdcW17u5s"
);

const dbUsers = FirebaseDb.collection("users");
const dbGroups = FirebaseDb.collection("groups");

FirebaseAuth.setPersistence("local");

export {
  Firebase,
  FirebaseApp,
  FirebaseAuth,
  FirebaseDb,
  FirebaseRealtime,
  FirebaseStorage,
  FirebaseMessaging,
  dbUsers,
  dbGroups,
};
