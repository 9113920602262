import { getGroup } from "services/database";
import { getDateFromAll } from "services/utilities";

const userPostLogin = async (user, dispatch) => {
  //const user = await getUser(userPost.userId);
  // console.log(user);
  if (user && user.email) {
    dispatch("auth/setUser", user);
    if (user.groupID) {
      getGroup(user.groupID).then((group) => {
        // console.log(group.members[0].resolutions);
        dispatch("ui/setLoading", false);
        dispatch("auth/setGroup", group);
        dispatch("ui/setScreen", "Home");
      });
    } else {
      dispatch("ui/setLoading", false);
      dispatch("ui/setScreen", "GroupSelect");
    }
  } else {
    dispatch("ui/setLoading", false);
    dispatch("ui/setScreen", "Auth");
  }
};

const getGoalRepeatText = (g) => {
  let text = g.target.toUpperCase();

  if (g.target === "weekly") {
    const weekNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const days = g.weekdays.map((d) => weekNames[d]);
    text += " ";
    text += days.join(", ");
  }

  if (g.target === "once") {
    let targetDate = getDateFromAll(g.targetDate);
    text = targetDate.toDateString();
  }
  return text;
};

export { userPostLogin, getGoalRepeatText };
