import React, { useState } from "react";
import { useStoreon } from "storeon/react";
import { makeStyles } from "@material-ui/core/styles";

import { getGoalRepeatText } from "services/common";
import { setGoalResponse } from "services/database";

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Slider from "@material-ui/core/Slider";

import ImageUpload from "modules/components/ImageUpload";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    overflow: "visible",
    color: "#fff",
    backgroundColor: "#7ea4ac",
  },
  actionButton: {
    margin: "0 1em",
    flexGrow: 1,
  },
  slider: {
    color: "#fff",
  },
  btnSuccess: {
    backgroundColor: "#8ddb8d",
  },
  btnDanger: {
    backgroundColor: "#f87e7e",
  },
  btnWarning: {
    backgroundColor: "#f6c772",
  },
  marginTop2: {
    marginTop: theme.spacing(2),
  },
  marginLeftRight4: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  marginTopRight2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  noPadTop: {
    paddingTop: 0,
  },
  uploadButton: {
    marginLeft: 10,
  },
}));

export default ({ goal, onAnswered }) => {
  const classes = useStyles();
  const { dispatch, auth } = useStoreon("auth");
  const [sliderVal, setSliderVal] = useState(0);
  const [filename, setFilename] = useState(null);

  const getMarks = (total) => {
    const ret = Array.from(Array(total + 1).keys()).map((n) => ({
      value: n,
      label: n,
    }));
    return ret;
  };

  const enterGoalResponse = (successes, fails) => {
    dispatch("ui/setLoading", true);
    // TODO: add warning /confirm dialog
    const updateData = {
      userID: auth.user.userID,
      resolutionID: goal.resolution.id,
      goalID: goal.id,
      successes,
      fails,
      filename,
    };
    setGoalResponse(updateData)
      .then((updatedGoal) => {
        dispatch("ui/setLoading", false);
        onAnswered(goal.id);
      })
      .catch((error) => {
        dispatch("ui/setLoading", false);
        dispatch("error/setError", {
          visible: true,
          text: `${error.message} (${error.code})`,
        });
      });
  };

  return (
    <Card className={classes.card} elevation={4}>
      <CardHeader
        title={goal.title}
        titleTypographyProps={{ variant: "h6" }}
        subheader={goal.resolution.title}
        subheaderTypographyProps={{
          variant: "subtitle2",
        }}
      />
      <CardContent className={classes.noPadTop}>
        <Typography variant="caption">{getGoalRepeatText(goal)}</Typography>
        <Typography variant="body1">{goal.description}</Typography>
        <Divider className={classes.marginTop2} />
        {goal._waiting === 1 && (
          <Typography variant="body1" className={classes.marginTopRight2}>
            Did you achieve this goal?
          </Typography>
        )}
        {goal._waiting > 1 && (
          <Typography variant="body1" className={classes.marginTopRight2}>
            How many days out of your target {goal._waiting} did you achieve
            this goal?
          </Typography>
        )}
        <ImageUpload returnFilename={(fname) => setFilename(fname)} />
      </CardContent>
      <CardActions disableSpacing>
        {goal._waiting === 1 && (
          <React.Fragment>
            <Button
              className={`${classes.actionButton} ${classes.btnSuccess}`}
              onClick={() => enterGoalResponse(1, 0)}
              variant="contained"
            >
              Yes
            </Button>
            <Button
              className={`${classes.actionButton} ${classes.btnDanger}`}
              onClick={() => enterGoalResponse(0, 1)}
              variant="contained"
            >
              No
            </Button>
          </React.Fragment>
        )}

        {goal._waiting > 1 && (
          <React.Fragment>
            <Slider
              className={`${classes.marginLeftRight4} ${classes.slider}`}
              classes={{ markLabel: classes.slider }}
              defaultValue={0}
              step={1}
              min={0}
              max={goal._waiting}
              aria-labelledby="goals-completed-slider"
              getAriaValueText={(v) => `${v} days`}
              valueLabelDisplay="on"
              onChangeCommitted={(e, val) => setSliderVal(val)}
              color="primary"
              marks={getMarks(goal._waiting)}
            />
            <Button
              className={`${classes.actionButton} ${classes.btnSuccess} ${classes.marginLeftRight4}`}
              onClick={() =>
                enterGoalResponse(sliderVal, goal._waiting - sliderVal)
              }
              variant="contained"
            >
              {" "}
              Submit{" "}
            </Button>
          </React.Fragment>
        )}
      </CardActions>
    </Card>
  );
};
