import React, { useState } from "react";
import { useStoreon } from "storeon/react";
import { makeStyles } from "@material-ui/core/styles";

import { FirebaseStorage } from "services/firebase";

import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";

import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    margin: 10,
  },
  uploadButton: {
    marginBottom: 5,
  },
  input: {
    display: "none",
  },
  image: {
    maxWidth: 300,
    border: "5px solid #ffee6f",
  },
  btnClear: {
    display: "block",
    margin: 5,
  },
}));

export default ({ returnFilename }) => {
  const classes = useStyles();
  const { auth } = useStoreon("auth");
  const [progress, setProgress] = useState(0);
  const [url, setUrl] = useState(null);
  const [filename, setFilename] = useState(null);

  const clearImage = () => {
    FirebaseStorage.images
      .child(filename)
      .delete()
      .then((response) => {
        setFilename(null);
        setUrl(null);
        returnFilename(null);
      });
  };

  const handleChange = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      const fname = `${Math.floor(Date.now() / 1000)}_${image.name}`;
      setFilename(fname);
      FirebaseStorage.init(auth.user.userID);
      const uploadTask = FirebaseStorage.images.child(fname).put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          setProgress(
            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
          );
        },
        (error) => {
          console.log(error);
        },
        () => {
          FirebaseStorage.images
            .child(fname)
            .getDownloadURL()
            .then((fburl) => {
              setUrl(fburl);
              returnFilename(fburl);
            });
        }
      );
    }
  };

  return (
    <span className={classes.root}>
      {!url && (
        <React.Fragment>
          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            type="file"
            onChange={handleChange}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              color="default"
              component="span"
              className={classes.uploadButton}
              startIcon={<CloudUploadIcon />}
            >
              Attach a Photo
            </Button>
          </label>
          <LinearProgress variant="determinate" value={progress} />
        </React.Fragment>
      )}
      {url && (
        <React.Fragment>
          <img src={url} alt="Uploaded" className={classes.image} />
          <Button className={classes.btnClear} onClick={clearImage}>
            Clear
          </Button>
        </React.Fragment>
      )}
    </span>
  );
};
