import React from "react";
import ReactDOM from "react-dom";
import { SnackbarProvider } from "notistack";

import { createStoreon } from "storeon";
import { StoreContext } from "storeon/react";
// import devtools from "storeon/devtools";

import authStore from "stores/auth";
import errorStore from "stores/error";
import uiStore from "stores/ui";

import App from "modules/App";

const store = createStoreon([
  authStore,
  errorStore,
  uiStore,
  // process.env.NODE_ENV !== "production" && devtools,
  //process.env.NODE_ENV !== "production" && logger // import logger from 'storeon/devtools/logger';
]);

/*window.addEventListener("load", function() {
  // Add original push state
  window.history.pushState({}, "");
});

window.addEventListener("popstate", function(event) {
  if (!window.confirm("Are you sure you want to exit?")) {
    window.history.pushState({}, "");
  }
}); */
const rootElement = document.getElementById("root");

ReactDOM.render(
  <StoreContext.Provider value={store}>
    <SnackbarProvider maxSnack={5}>
      <App />
    </SnackbarProvider>
  </StoreContext.Provider>,
  rootElement
);
