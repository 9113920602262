import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useStoreon } from "storeon/react";

import {
  copyWithout,
  isSameDate,
  isWithinDays,
  getResponsesCounts,
  getDateFromAll,
} from "services/utilities";
import { getGoalResponses } from "services/database";

import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";

import ChatIcon from "@material-ui/icons/Chat";

import GoalCard from "modules/components/GoalCard";
import EventCard from "modules/components/EventCard";
import Chat from "modules/Chat/Chat";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </Typography>
  );
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  gray: {
    color: "#acacac",
  },
  chatButton: {
    position: "fixed",
    right: 20,
    bottom: 20,
    zIndex: 999,
  },
  chatDialog: {
    marginLeft: "10%",
  },
  btnMore: {
    display: "block",
    margin: "20px auto",
  },
}));

export default () => {
  const classes = useStyles();
  const { dispatch, auth, ui } = useStoreon("auth", "ui");
  const [todayGoals, setTodayGoals] = useState([]);
  const [upcomingGoals, setUpcomingGoals] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [tab, setTab] = useState(0);
  const [chatOpen, setChatOpen] = useState(false);
  const [visibleEvents, setVisibleEvents] = useState(10);

  useEffect(() => {
    setPastEvents([]);
    const goals = auth.group.members.reduce(
      (arr, user) => {
        const userNew = copyWithout(user, ["resolutions"]);
        user.resolutions &&
          user.resolutions.forEach((res) => {
            const now = new Date();
            now.setHours(0, 0, 0, 0);

            res.start = getDateFromAll(res.start);
            res.end = getDateFromAll(res.end);
            if (now >= res.start && now <= res.end) {
              const resNew = copyWithout(res, ["goals"]);
              res.goals &&
                res.goals.forEach((goal) => {
                  let showToday = false;
                  let showUpcoming = false;

                  if (goal.target === "daily") {
                    showToday = true;
                    showUpcoming = true;
                  } else if (goal.target === "weekly") {
                    showToday = goal.weekdays.includes(now.getDay())
                      ? true
                      : false;
                    showUpcoming = true;
                  } else if (goal.target === "once") {
                    const targetDate = getDateFromAll(goal.targetDate);
                    showToday = isSameDate(targetDate, now);
                    showUpcoming =
                      targetDate > now && isWithinDays(targetDate, 7);
                  }

                  const card = {
                    ...goal,
                    resolution: resNew,
                    user: userNew,
                  };

                  const goalResponseCounts = getResponsesCounts(goal, resNew);
                  if (
                    goalResponseCounts.waiting > 0 &&
                    !!goal.respondedLast &&
                    isSameDate(getDateFromAll(goal.respondedLast), now)
                  ) {
                    showToday = false;
                  }

                  if (showToday) {
                    arr.today.push(card);
                  }
                  if (showUpcoming) {
                    arr.upcoming.push(card);
                  }
                  // Get Responses
                  getGoalResponses(user.userID, res.id, goal.id).then(
                    (responses) => {
                      responses = responses.map((res) => ({
                        ...res,
                        resolution: resNew,
                        user: userNew,
                        goal,
                      }));
                      setPastEvents((prev) =>
                        [...prev, ...responses].sort(
                          (a, b) => b.createdAt.seconds - a.createdAt.seconds
                        )
                      );
                    }
                  );
                });
            }
          });
        return arr;
      },
      { today: [], upcoming: [] }
    );
    setTodayGoals(goals.today);
    setUpcomingGoals(goals.upcoming);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [auth.group, auth, ui.refreshData]);

  const goalCompleted = (id) => {
    dispatch("ui/refreshData", true);
    setTimeout(() => {
      setTodayGoals(todayGoals.filter((g) => g.id !== id));
      setUpcomingGoals(upcomingGoals.filter((g) => g.id !== id));
    }, 100);
    //TODO: hacky, just replace auth.group.members with new value for goal that has ReposndedLast today
  };

  const switchToTab = (tab, from) => {
    if (tab > 2) {
      tab = 2;
    }
    setTab(tab);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  return (
    <React.Fragment>
      <AppBar position="static" color="default">
        <Tabs
          value={tab}
          onChange={(e, newVal) => switchToTab(newVal, 1)}
          indicatorColor="secondary"
          textColor="secondary"
          variant="fullWidth"
          aria-label="Goal Tabs"
        >
          <Tab label="TODAY" {...a11yProps(0)} />
          <Tab label="UPCOMING" {...a11yProps(1)} />
          <Tab label="EVENTS" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={tab} index={0}>
        <Typography variant="subtitle1" gutterBottom className={classes.gray}>
          TODAY
        </Typography>

        {todayGoals.map((goal, goal_index) => (
          <GoalCard
            key={`todayGoal_${goal_index}`}
            goal={goal}
            onAnswered={goalCompleted}
            askResponses={true}
            isToday={true}
          />
        ))}
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Typography variant="subtitle1" gutterBottom className={classes.gray}>
          7-DAY UPCOMING EVENTS
        </Typography>

        {upcomingGoals.map((goal, goal_index) => (
          <GoalCard
            key={`todayGoal_${goal_index}`}
            goal={goal}
            onAnswered={goalCompleted}
            askResponses={false}
            isToday={false}
          />
        ))}
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <Typography variant="subtitle1" gutterBottom className={classes.gray}>
          PAST EVENTS
        </Typography>

        {pastEvents
          .filter((ev, i) => i < visibleEvents)
          .map((event, event_index) => (
            <EventCard key={`event_${event_index}`} event={event} />
          ))}
        {visibleEvents < pastEvents.length && (
          <Button
            onClick={() => setVisibleEvents((ve) => ve + 10)}
            className={classes.btnMore}
          >
            -Load More-
          </Button>
        )}
      </TabPanel>

      <Fab
        color="primary"
        aria-label="chat"
        className={classes.chatButton}
        onClick={() => setChatOpen(true)}
      >
        <ChatIcon />
      </Fab>

      <Dialog
        fullScreen
        open={chatOpen}
        onClose={() => setChatOpen(false)}
        TransitionComponent={Transition}
        className={classes.chatDialog}
      >
        <Chat onClose={() => setChatOpen(false)} />
      </Dialog>
    </React.Fragment>
  );
};
