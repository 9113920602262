export default store => {
  store.on("@init", () => ({
    ui: {
      loading: false,
      appScreen: "",
      refreshData: false
    }
  }));

  store.on("ui/setLoading", ({ ui }, payload) => {
    const ret = { ...ui, loading: payload };
    return { ui: ret };
  });

  store.on("ui/setScreen", ({ ui }, payload) => {
    const ret = { ...ui, appScreen: payload };
    return { ui: ret };
  });

  store.on("ui/refreshData", ({ ui }, payload) => {
    const ret = { ...ui, refreshData: !ui.refreshData };
    return { ui: ret };
  });
};
