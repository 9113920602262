import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useStoreon } from "storeon/react";

import Container from "@material-ui/core/Container";

import MainTop from "modules/Main/MainTop";
import MainContent from "modules/Main/MainContent";
import MainResponses from "modules/Main/MainResponses";

import { getResponsesCounts, getDateFromAll } from "services/utilities";

const useStyles = makeStyles((theme) => ({
  textLeft: {
    textAlign: "left",
  },
}));

export default () => {
  const classes = useStyles();
  const { auth } = useStoreon("auth");
  const [pendingResponses, setPendingResponses] = useState([]);

  useEffect(() => {
    const userGoals = [].concat.apply(
      [],
      auth.user.resolutions.map((r) =>
        r.goals.map((g) => ({
          ...g,
          resolution: {
            id: r.id,
            title: r.title,
            start: getDateFromAll(r.start),
          },
        }))
      )
    );
    const reponses = [];
    userGoals.forEach((goal) => {
      const goalResponseCounts = getResponsesCounts(goal, goal.resolution);
      goal._should = goalResponseCounts.should;
      goal._waiting = goalResponseCounts.waiting;
      if (goal._should > goal.responseDays) {
        reponses.push(goal);
      }
    });
    setPendingResponses(reponses);
  }, [auth.user]);

  return (
    <React.Fragment>
      <MainTop />

      <Container className={classes.textLeft}>
        {pendingResponses.length === 0 && <MainContent />}
        {pendingResponses.length > 0 && (
          <MainResponses
            pendingResponses={pendingResponses}
            setPendingResponses={setPendingResponses}
          />
        )}
      </Container>
      <p>Version: {process.env.REACT_APP_VERSION}</p>
    </React.Fragment>
  );
};
