import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useStoreon } from "storeon/react";

import {
  chatMessagesGet,
  chatMessagePost,
  sendGroupNotification,
} from "services/database";

import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Fab from "@material-ui/core/Fab";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

import CloseIcon from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Send";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "block",
    overflow: "hidden",
  },
  closeBtn: {
    position: "fixed",
    left: 20,
    top: 20,
    zIndex: 999,
  },
  inline: {
    display: "inline",
  },
  chatWrapper: {
    alignItems: "flex-end",
  },
  chatWrapperOwn: {
    textAlign: "right",
  },
  chatAvatar: {
    marginBottom: 16,
  },
  chatName: {
    color: "#bababa",
    fontSize: "0.8em",
    marginLeft: 5,
    marginRight: 5,
  },
  chatDate: {
    display: "block",
    marginTop: 2,
    color: "#c2c2c2",
    marginLeft: 5,
    marginRight: 5,
    fontSize: "0.7em",
  },
  chatText: {
    backgroundColor: "#f1f0f0",
    color: "rgba(0, 0, 0, 1)",
    margin: "1px 0",
    padding: "6px 12px",
    display: "inline-block",
    borderRadius: 15,
  },
  chatTextOwn: {
    backgroundColor: "#6699cc",
    color: "white",
  },
  chatArea: {
    position: "relative",
    width: "100%",
    maxHeight: "100vh",
    paddingBottom: 100,
    overflow: "auto",
  },
  chatInput: {
    width: "90%",
    overflow: "hidden",
    position: "fixed",
    bottom: 0,
    right: 0,
    marginLeft: "10%",
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 20,
    paddingTop: 20,
    background: "#fff",
  },
}));

export default ({ onClose }) => {
  const classes = useStyles();
  const { dispatch, auth } = useStoreon("auth");
  const messagesEndRef = useRef(null);
  const [chatMessage, setChatMessage] = useState("");
  const [newMessages, setNewMessages] = useState([]);
  const [lastKey, setLastKey] = useState(null);
  const [showLoadMore, setShowLoadMore] = useState(true);

  useEffect(() => {
    let messagesDB;
    if (auth.group.id) {
      messagesDB = chatMessagesGet(auth.group.id, null);
      let i = 0;
      messagesDB.on("child_added", (snapshot) => {
        i++;
        var message = snapshot.val();
        setNewMessages((m) => [...m, { ...message, id: snapshot.key }]);
        setTimeout(() => messagesEndRef.current.scrollIntoView());
        if (i === 1) {
          setLastKey(snapshot.key);
        }
      });
    }

    return () => {
      messagesDB.off("child_added");
    };
  }, [auth.group.id]);

  const sendMessage = (ev) => {
    if ((ev === 1 || ev.charCode === 13) && chatMessage.trim() !== "") {
      chatMessagePost({
        chatId: auth.group.id,
        user: auth.user,
        message: chatMessage,
      });
      setChatMessage("");
      setTimeout(() => messagesEndRef.current.scrollIntoView());
      // Also send notifcation:
      sendGroupNotification(
        auth.user.userID,
        auth.group,
        `${auth.user.firstname} sent a message`,
        `"${chatMessage.slice(0, 20)}..."`
      );
    }
  };

  const loadMoreMessages = () => {
    dispatch("ui/setLoading", true);
    const messagesDB = chatMessagesGet(auth.group.id, lastKey);
    messagesDB.once("value", (snapshot) => {
      const olderMessages = [];
      snapshot.forEach((child) => {
        if (!newMessages.find((m) => m.id === child.key)) {
          var message = child.val();
          olderMessages.unshift({ ...message, id: child.key });
        }
      });
      if (olderMessages.length > 0) {
        olderMessages.reverse();
        setLastKey(olderMessages[0].id);
        setNewMessages((m) => [...olderMessages, ...m]);
      } else {
        setShowLoadMore(false);
      }
      dispatch("ui/setLoading", false);
    });
  };

  return (
    <div className={classes.root}>
      <Fab
        color="secondary"
        aria-label="chat"
        className={classes.closeBtn}
        onClick={onClose}
      >
        <CloseIcon />
      </Fab>
      {showLoadMore && <Button onClick={loadMoreMessages}>-Load More-</Button>}
      <List className={classes.chatArea} dense={true}>
        {newMessages.map((mess) => (
          <ListItem
            key={mess.id}
            dense={true}
            className={`${classes.chatWrapper} ${
              mess.user.userID === auth.user.userID && classes.chatWrapperOwn
            }`}
          >
            {mess.user.userID !== auth.user.userID && (
              <ListItemAvatar className={classes.chatAvatar}>
                <Avatar
                  alt="Remy Sharp"
                  src={mess.user.blob || mess.user.pic}
                />
              </ListItemAvatar>
            )}
            <ListItemText
              primary={
                mess.user.userID !== auth.user.userID && (
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.chatName}
                  >
                    {mess.user.firstname} {mess.user.lastname}
                  </Typography>
                )
              }
              secondary={
                <React.Fragment>
                  <span
                    className={`${classes.chatText} ${
                      mess.user.userID === auth.user.userID &&
                      classes.chatTextOwn
                    }`}
                  >
                    {mess.text}
                  </span>
                  <span className={classes.chatDate}>
                    {new Date(mess.added).toDateString()} -{" "}
                    {new Date(mess.added).toLocaleTimeString()}
                  </span>
                </React.Fragment>
              }
            />
          </ListItem>
        ))}
        <ListItem ref={messagesEndRef}></ListItem>
      </List>
      <TextField
        autoFocus={true}
        rowsMax={3}
        placeholder="Send a message..."
        onChange={(e) => setChatMessage(e.target.value)}
        value={chatMessage}
        onKeyPress={sendMessage}
        className={classes.chatInput}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => sendMessage(1)}>
                <SendIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};
