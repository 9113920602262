import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useStoreon } from "storeon/react";
import "date-fns";

import { getGoalRepeatText } from "services/common";
import { useInputValue } from "services/hooks";
import { createResolution, getUser } from "services/database";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";

import DeleteIcon from "@material-ui/icons/Delete";

import GoalCreate from "modules/Resolution/GoalCreate";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  root: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.main,
  },
  margin: {
    display: "block",
    margin: theme.spacing(2),
  },
  marginTopBottom: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  card: {
    padding: theme.spacing(2),
    width: "94%",
    margin: "20px auto",
  },
  topButton: {
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
  dialogToolbar: {
    //justifyContent: "space-between"
  },
}));

export default () => {
  const now = new Date();
  let inTwoWeeks = new Date(); // is now inOneWeek
  now.setHours(0, 0, 0, 0);
  inTwoWeeks = new Date(inTwoWeeks.setDate(now.getDate() + 7));

  const classes = useStyles();
  const { dispatch, auth } = useStoreon("auth");
  const { ...resTitle } = useInputValue("");
  const [resStart, setResStart] = useState(now.toISOString());
  const [resEnd, setResEnd] = useState(inTwoWeeks.toISOString());
  const [openDialog, setOpenDialog] = useState(false);
  const [resolution, setResolution] = useState({
    title: "",
    start: "",
    end: "",
    goals: [],
  });

  const createSubmit = (e) => {
    e.preventDefault();
    dispatch("ui/setLoading", true);
    const title = resTitle.value.trim();
    const start = new Date(resStart);
    start.setHours(0, 0, 0, 0);
    const end = new Date(resEnd);
    end.setHours(23, 59, 59, 0);
    if (!title || !start || !end) {
      dispatch("error/setError", {
        visible: true,
        text: `Please, fill in all the fields.`,
      });
      dispatch("ui/setLoading", false);
      return false;
    }
    const newResolution = {
      ...resolution,
      title,
      start,
      end,
    };
    createResolution(newResolution, auth.user.userID)
      .then(async (created) => {
        const updatedUser = await getUser(auth.user.userID);
        const newMembers = auth.group.members.map((m) =>
          m.userID === updatedUser.userID ? updatedUser : m
        );
        const updatedGroup = { ...auth.group, members: newMembers };
        dispatch("auth/setUser", updatedUser);
        dispatch("auth/setGroup", updatedGroup);
        dispatch("ui/setLoading", false);
        dispatch("ui/setScreen", "Home");
      })
      .catch((error) => {
        dispatch("ui/setLoading", false);
        dispatch("error/setError", {
          visible: true,
          text: `${error.message} (${error.code})`,
        });
      });
  };

  const setGoalData = (goal) => {
    console.log(goal);
    if (goal) {
      setResolution((r) => ({
        ...r,
        goals: [...resolution.goals, goal],
      }));
    }
  };

  const removeGoal = (goal_index) => {
    const goals = resolution.goals;
    goals.splice(goal_index, 1);
    setResolution((r) => ({ ...r, goals }));
  };

  return (
    <Container maxWidth="md" className={classes.root}>
      <Paper elevation={3} className={classes.card}>
        <div className={classes.topButton}>
          <Button
            variant="contained"
            onClick={() => dispatch("ui/setScreen", "Home")}
          >
            &lt; Return
          </Button>
        </div>
        <Typography variant="h4" component="h1">
          Create a new resolution
        </Typography>
        <form
          className={classes.marginTopBottom}
          noValidate
          onSubmit={createSubmit}
        >
          <p style={{ textAlign: "left", marginBottom: -10 }}>
            Examples:{" "}
            <em>
              Get in shape, Choose my first tattoo, Learn more about Ancient
              Greek Mythology, Try being a vegetarian, Get better abs, Train my
              dog to sit
            </em>
          </p>
          <TextField
            {...resTitle}
            className={classes.marginTopBottom}
            id="input-resolution-title"
            variant="outlined"
            autoFocus={true}
            fullWidth
            label="Resolution Title"
            color="secondary"
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid
              container
              justify="space-around"
              className={classes.marginTopBottom}
            >
              <DatePicker
                disabled={true}
                value={resStart}
                onChange={(e) => setResStart(e)}
                margin="normal"
                id="date-picker-resolution-start"
                label="Resolution Start Date"
                format="EEE, d MMMM yyyy"
                color="secondary"
                minDate={now}
                maxDate={inTwoWeeks}
              />
              <DatePicker
                disabled={true}
                value={resEnd}
                onChange={(e) => setResEnd(e)}
                margin="normal"
                id="date-picker-resolution-end"
                label="Resolution End Date"
                format="EEE, d MMMM yyyy"
                color="secondary"
                minDate={now}
                maxDate={inTwoWeeks}
              />
            </Grid>
          </MuiPickersUtilsProvider>

          <Typography variant="h5" component="h2">
            Resolution goals
          </Typography>
          <Typography variant="h6" component="h4">
            The small steps you'll take to meet your resolution
          </Typography>

          <List dense={true}>
            {resolution.goals.map((g, ind) => (
              <React.Fragment key={`goal_${ind}`}>
                <ListItem>
                  <ListItemText
                    primary={`${ind + 1}) ${g.title}`}
                    secondary={getGoalRepeatText(g)}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => removeGoal(ind)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider component="li" />
              </React.Fragment>
            ))}
          </List>
          <Button
            variant="contained"
            color="secondary"
            className={classes.marginTopBottom}
            onClick={() => setOpenDialog(true)}
          >
            Add Goal
          </Button>

          <div></div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.marginTopBottom}
          >
            Create Resolution
          </Button>
        </form>
      </Paper>

      <Dialog fullScreen open={openDialog} onClose={() => setOpenDialog(false)}>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.dialogToolbar}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpenDialog(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Add new goal
            </Typography>
          </Toolbar>
        </AppBar>
        <GoalCreate setGoalData={setGoalData} setOpenDialog={setOpenDialog} />
      </Dialog>
    </Container>
  );
};
