import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useStoreon } from "storeon/react";

import { getGoalRepeatText } from "services/common";
import { sendNotification } from "services/database";
import { datesDiffInDays, getDateFromAll } from "services/utilities";

import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import Divider from "@material-ui/core/Divider";

import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import HelpIcon from "@material-ui/icons/Help";

import Comments from "modules/components/Comments";

/* Happy Emoji, Sad, Heart, Thumbs Up. Question mark */
const actions = [
  { icon: <SentimentVerySatisfiedIcon />, name: ":)" },
  { icon: <SentimentVeryDissatisfiedIcon />, name: ":(" },
  { icon: <ThumbUpIcon />, name: "(y)" },
  { icon: <HelpIcon />, name: "?" },
  { icon: <FavoriteIcon />, name: "<3" },
];

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    overflow: "visible",
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    border: "none",
  },
  marginTopBottom1: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  marginTopBottom2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  actionIcon: {
    margin: 5,
    flexGrow: 1,
  },
  bgSucces: {
    backgroundColor: "#e2ffe2",
  },
  bgFail: {
    backgroundColor: "#ffeded",
  },
  noPadTop: {
    paddingTop: 0,
  },
  actionButton: {
    margin: "0 1em",
    flexGrow: 1,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    backgroundSize: "contain",
  },
}));

export default ({ event }) => {
  const classes = useStyles();
  const { auth } = useStoreon("auth");
  const [menuEl, setMenuEl] = useState(null);
  const menuOpen = Boolean(menuEl);

  const completion = useMemo(() => {
    const durationInDays = datesDiffInDays(
      event.resolution.start,
      event.resolution.end
    );
    const daysSoFar = datesDiffInDays(event.resolution.start, new Date());
    return parseInt((daysSoFar * 100) / durationInDays);
  }, [event.resolution.start, event.resolution.end]);

  const sendMessage = (code) => {
    setMenuEl(null);
    const text = `${event.goal.title}: ${code}`;
    sendNotification(
      auth.user.userID,
      `${auth.user.firstname} sent you a message`,
      text,
      event.user
    ).then((res) => {
      console.log(res);
    });
  };

  const responseTitle = useMemo(() => {
    const message =
      event.responseDays === 1
        ? `${
            event.responseSuccess === 0 ? "did not complete" : "completed"
          } the following goal:`
        : `completed the following goal ${event.responseSuccess} out of the ${event.responseDays} times set.`;
    return `${event.user.firstname} ${message}`;
  }, [event]);

  return (
    <Card
      className={`${classes.card} ${
        event.responseSuccess === 0 ? classes.bgFail : classes.bgSucces
      }`}
      elevation={4}
    >
      <CardHeader
        avatar={
          <Avatar
            className={classes.avatar}
            src={event.user.blob || event.user.pic}
          />
        }
        action={
          <React.Fragment>
            <IconButton
              aria-label="reactions"
              aria-controls="reaction-menu"
              onClick={(e) => setMenuEl(e.currentTarget)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="reaction-menu"
              anchorEl={menuEl}
              keepMounted
              open={menuOpen}
              onClose={() => setMenuEl(null)}
            >
              {actions.map((action) => (
                <MenuItem
                  key={action.name}
                  onClick={() => sendMessage(action.name)}
                >
                  <ListItemIcon>{action.icon}</ListItemIcon>
                </MenuItem>
              ))}
            </Menu>
          </React.Fragment>
        }
        title={responseTitle}
        titleTypographyProps={{ color: "secondary", variant: "h6" }}
        subheader={event.goal.title}
        subheaderTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
      />
      {event.filename && (
        <CardMedia className={classes.media} image={event.filename} />
      )}
      <CardContent className={classes.noPadTop}>
        <Typography variant="caption">
          {getDateFromAll(event.createdAt).toDateString()}{" "}
          {getDateFromAll(event.createdAt).toLocaleTimeString()}
        </Typography>
        <br />
        <Typography variant="caption">
          {getGoalRepeatText(event.goal)}
        </Typography>
        <Typography variant="body1">{event.goal.description}</Typography>
        <Divider className={classes.marginTopBottom2} />
        <Typography variant="subtitle2">
          {event.resolution.title} ({completion}%)
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <div style={{ width: "100%" }}>
          <Comments chatId={event.id}></Comments>
        </div>
      </CardActions>
    </Card>
  );
};
