import {
  Firebase,
  FirebaseAuth,
  FirebaseRealtime,
  FirebaseMessaging,
  dbUsers,
  dbGroups,
} from "services/firebase";

import { asyncForEach } from "services/utilities";

/*const AuthSignin = user => {
  return new Promise((resolve, reject) => {
    if (user) {
      FirebaseAuth.signInWithEmailAndPassword(user.email, user.password)
        .then(usr => {
          resolve(usr);
        })
        .catch(error => {
          reject(error);
        });
    } else {
      reject({ message: "Please enter your email and password" });
    }
  });
};

const AuthSignup = user => {
  return new Promise((resolve, reject) => {
    if (user) {
      FirebaseAuth.createUserWithEmailAndPassword(user.email, user.password)
        .then(usr => {
          const uid = usr.user.uid;
          const now = new Date();
          dbUsers
            .doc(uid)
            .set({
              userID: uid,
              email: user.email,
              createdAt: now,
              updatedAt: now
            })
            .then(() => {
              resolve(true);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch(error => {
          reject(error);
        });
    } else {
      reject("No user");
    }
  });
};*/

const userPicToBlob = async (user) => {
  let blob = await fetch(user.pic).then((r) => r.blob());
  let dataUrl = await new Promise((resolve) => {
    let reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
  return dataUrl;
};

const AuthCheck = () => {
  return new Promise((resolve, reject) => {
    FirebaseAuth.onAuthStateChanged(async (userData) => {
      if (!userData) {
        resolve(null);
        return false;
      }
      const user = await getUser(userData.userID);
      if (user && user.email) {
        // check if has blob, or create it
        if (!user.blob && user.pic) {
          const dataUrl = await userPicToBlob(user);
          await updateUser(user.userID, { blob: dataUrl });
        }
        resolve(user);
      } else {
        resolve(null);
      }
    });
  });
};

const addUserToDb = (result) => {
  return new Promise((resolve, reject) => {
    const userData = result.user;
    if (!userData) {
      resolve(null);
      return false;
    }

    //check if exists on DB or is New user:
    const uid = userData.uid;
    const now = new Date();
    getUser(uid).then(async (existing) => {
      if (!existing.email) {
        const user = {
          userID: uid,
          groupID: null,
          email: userData.email,
          firstname: userData.displayName.split(" ")[0],
          lastname: userData.displayName.split(" ")[1] || "",
          fbname: null,
          pic: userData.photoURL,
          createdAt: now,
          updatedAt: now,
        };
        const dataUrl = await userPicToBlob(user);
        user.blob = dataUrl;
        dbUsers
          .doc(uid)
          .set(user)
          .then(() => {
            resolve({ ...user, resolutions: [] });
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        resolve(existing);
      }
    });
  });
};

const AuthSocial = (social) => {
  let provider = null;
  if (social === "google") provider = new Firebase.auth.GoogleAuthProvider();
  if (social === "facebook") {
    provider = new Firebase.auth.FacebookAuthProvider();
    provider.addScope("public_profile,email");
  }

  return new Promise((resolve, reject) => {
    //FirebaseAuth.signInWithPopup(provider)
    FirebaseAuth.signInWithRedirect(provider)
      .then(async (result) => {
        const ret = addUserToDb(result);
        resolve(ret);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const AuthCheckRedirect = () => {
  return new Promise((resolve, reject) => {
    FirebaseAuth.getRedirectResult()
      .then(async (result) => {
        const ret = await addUserToDb(result);
        resolve(ret);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const AuthLogout = () => {
  FirebaseAuth.signOut()
    .then(() => {
      window.location.reload();
    })
    .catch((error) => {
      console.error(error);
    });
};

const getUser = (uid) => {
  return new Promise((resolve, reject) => {
    uid = uid || FirebaseAuth.currentUser.uid;
    const docUser = dbUsers.doc(uid);
    const now = Firebase.firestore.Timestamp.fromDate(new Date());
    // const now = new Date();

    docUser
      .get()
      .then(async (profile) => {
        const currentUser = profile.data();

        // Only get resolutions that have not ended
        const resolutionsAll = await docUser
          .collection("resolutions")
          .where("end", ">=", now)
          .orderBy("end", "desc")
          .get();
        let resolutions = [];
        for (let i = 0; i < resolutionsAll.docs.length; i++) {
          const res = resolutionsAll.docs[i];
          // Only get resolutions that have started
          if (res.data().start <= now) {
            const goalsAll = await res.ref
              .collection("goals")
              .orderBy("createdAt")
              .get();
            resolutions.push({
              ...res.data(),
              id: res.id,
              goals: goalsAll.docs.map((g) => ({ ...g.data(), id: g.id })),
            });
          }
        }

        resolve({ ...currentUser, resolutions });
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const updateUser = (uid, data) => {
  return new Promise((resolve, reject) => {
    dbUsers
      .doc(uid)
      .update(data)
      .then((profile) => {
        resolve(profile);
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const getGroup = (goupID) => {
  return new Promise((resolve, reject) => {
    dbGroups
      .doc(goupID)
      .get()
      .then(async (groupData) => {
        const group = groupData.data();
        group.id = groupData.id;
        // OPTIONAL: Get full detaisl on all group users
        const promises = [];
        group.members.forEach((member) => {
          promises.push(getUser(member));
        });
        const membersFull = await Promise.all(promises);
        resolve({ ...group, members: membersFull });
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const getAllGroups = () => {
  // TODO: only show public groups
  return new Promise((resolve, reject) => {
    dbGroups
      .orderBy("createdAt")
      .get()
      .then((groupsAll) => {
        const groups = groupsAll.docs.map((gr) => {
          return { ...gr.data(), id: gr.id };
        });
        resolve(groups);
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const createGroup = (data) => {
  const now = new Date();
  data.createdAt = now;
  data.updatedAt = now;
  return new Promise((resolve, reject) => {
    dbGroups
      .add(data)
      .then(async (added) => {
        const newGroup = getGroup(added.id);
        resolve(newGroup);
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const updateGroup = (groupID, data) => {
  return new Promise((resolve, reject) => {
    dbGroups
      .doc(groupID)
      .update(data)
      .then((group) => {
        resolve(group);
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const addMemberToGroup = (groupID, memberID, password) => {
  return new Promise((resolve, reject) => {
    const groupRef = dbGroups.doc(groupID);
    groupRef
      .get()
      .then((gr) => {
        const groupData = gr.data();
        if (groupData.members.length >= groupData.max) {
          reject({
            message: "Cannot join Group. Already full.",
            code: "group-max-reached",
          });
          return false;
        }
        if (groupData.isPrivate && password !== groupData.password) {
          reject({
            message:
              "You have entered a wrong password for this private group.",
            code: "group-private-wrong-pass",
          });
          return false;
        }
        groupRef
          .update({
            members: Firebase.firestore.FieldValue.arrayUnion(memberID),
          })
          .then(async (group) => {
            await updateUser(memberID, { groupID });
            resolve(group);
          })
          .catch((error) => {
            reject(error);
            console.error(error);
          });
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const removeMemberFromGroup = (groupID, memberID) => {
  return new Promise((resolve, reject) => {
    dbGroups
      .doc(groupID)
      .update({
        members: Firebase.firestore.FieldValue.arrayRemove(memberID),
      })
      .then(async (result) => {
        await updateUser(memberID, { groupID: null });
        // check and delete group if no more members left
        const group = await dbGroups.doc(groupID).get();
        const data = group.data();
        if (data.members.length === 0) {
          await dbGroups.doc(groupID).delete();
          resolve(group);
        } else {
          resolve(group);
        }
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const createResolution = (data, uid) => {
  const now = new Date();
  const resolution = {
    title: data.title,
    start: data.start,
    end: data.end,
    createdAt: now,
    updatedAt: now,
  };
  return new Promise((resolve, reject) => {
    dbUsers
      .doc(uid)
      .collection("resolutions")
      .add(resolution)
      .then(async (resolutionAdded) => {
        const promises = [];
        data.goals.forEach((goal) => {
          promises.push(
            resolutionAdded.collection("goals").add({
              ...goal,
              createdAt: now,
              updatedAt: now,
            })
          );
        });
        const allAdded = await Promise.all(promises);
        resolve(allAdded);
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const deleteResolution = (resolutionId, uid) => {
  return new Promise(async (resolve, reject) => {
    const resolution = await dbUsers
      .doc(uid)
      .collection("resolutions")
      .doc(resolutionId);
    const allGoals = await resolution.collection("goals").get();

    await asyncForEach(allGoals.docs, async (goal) => {
      await deleteAllDocsFrom(goal, "responses");
    });
    await deleteAllDocsFrom(resolution, "goals");
    await resolution.delete();
    // @TODO: also delete comments for each goal from realtime

    resolve(true);
  });
};

const deleteAllDocsFrom = (parent, collectionName) => {
  if (parent.ref) {
    parent = parent.ref;
  }
  return new Promise(async (resolve, reject) => {
    const allDocs = await parent.collection(collectionName).get();
    await asyncForEach(allDocs.docs, async (doc) => {
      await doc.ref.delete();
      console.log(doc.ref);
    });

    resolve(true);
  });
};

const setGoalResponse = ({
  userID,
  resolutionID,
  goalID,
  successes,
  fails,
  filename,
}) => {
  const now = new Date();
  // TODO: only show public groups
  const updateData = {
    responseDays: Firebase.firestore.FieldValue.increment(successes + fails),
    responseSuccess: Firebase.firestore.FieldValue.increment(successes),
    respondedLast: now,
  };
  return new Promise((resolve, reject) => {
    const goalRef = dbUsers
      .doc(userID)
      .collection("resolutions")
      .doc(resolutionID)
      .collection("goals")
      .doc(goalID);

    goalRef
      .update(updateData)
      .then(async (upd) => {
        await goalRef.collection("responses").add({
          ...updateData,
          filename,
          createdAt: now,
          updatedAt: now,
        });
        resolve(true);
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const getGoalResponses = (userID, resolutionID, goalID) => {
  // TODO: only show public groups
  return new Promise((resolve, reject) => {
    dbUsers
      .doc(userID)
      .collection("resolutions")
      .doc(resolutionID)
      .collection("goals")
      .doc(goalID)
      .collection("responses")
      //.where("end", ">=", now)
      .get()
      .then((responsesAll) => {
        const responses = responsesAll.docs.map((res) => {
          return { ...res.data(), id: res.id };
        });
        resolve(responses);
      })
      .catch((error) => {
        reject(error);
        console.error(error);
      });
  });
};

const chatMessagePost = ({ chatId, message, user }) => {
  FirebaseRealtime.ref(`${chatId}/`).push({
    text: message,
    user,
    added: Date.now(),
  });
};

const chatMessagesGet = (chatId, key) => {
  return key
    ? FirebaseRealtime.ref(`${chatId}/`).orderByKey().endAt(key).limitToLast(50)
    : FirebaseRealtime.ref(`${chatId}/`).orderByKey().limitToLast(50);
};

const askForPermissioToReceiveNotifications = async (uid) => {
  try {
    await FirebaseMessaging.requestPermission();
    const token = await FirebaseMessaging.getToken();
    await updateUser(uid, { token });
    console.log("Token:", token);
    return token;
  } catch (error) {
    console.error(error);
  }
};

//TODO: send notification to ONE USER

const sendNotification = async (from_uid, title, body, to_user) => {
  // TODO: DONT use the server key. Use serverless functions instead?
  try {
    const res = await fetch("https://fcm.googleapis.com/fcm/send", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `key=AAAAl_e121g:APA91bFbSdlEwGlKCjCV7udr246ouGy0Tg4AZ5SD5s_f8NL-MWCIw8mM26CThmJHJLCLy0yRsbRU26fiSJuY0UToZC8Y9YYUIyyv86qf0uInqB83d0CJW_qgWLTcKsQZmjzKwgDngopi`,
      },
      body: JSON.stringify({
        notification: {
          title,
          body,
          click_action: "https://goals.cortexial.com",
          icon: "https://goals.cortexial.com/logo512.png",
          data: {
            uid: from_uid,
          },
        },
        to: to_user.token,
      }),
    });
    const json = await res.json();
    return json;
  } catch (error) {
    return error;
  }
};

const sendGroupNotification = (from_uid, group, title, body) => {
  // TODO: get user details from DB not, group, so it has up-to-date notification tokens and user List
  group.members.forEach(async (user) => {
    if (from_uid !== user.userID) {
      sendNotification(from_uid, title, body, user);
    }
  });
};

export {
  /*AuthSignin, AuthSignup,*/
  AuthSocial,
  AuthCheck,
  AuthCheckRedirect,
  AuthLogout,
  getUser,
  updateUser,
  getGroup,
  getAllGroups,
  createGroup,
  updateGroup,
  addMemberToGroup,
  removeMemberFromGroup,
  createResolution,
  getGoalResponses,
  setGoalResponse,
  chatMessagePost,
  chatMessagesGet,
  askForPermissioToReceiveNotifications,
  sendNotification,
  sendGroupNotification,
  deleteResolution,
};
