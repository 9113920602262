import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import ResponseCard from "modules/components/ResponseCard";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: "left",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

export default ({ pendingResponses, setPendingResponses }) => {
  const classes = useStyles();

  const onAnswered = goalID => {
    setPendingResponses(list => list.filter(g => g.id !== goalID));
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4">Welcome Back!</Typography>
      <Typography variant="h6">How did you do with the following {pendingResponses.length} goal{pendingResponses.length > 1 ? 's' : ''}?</Typography>
      {pendingResponses.map(goal => (
        <ResponseCard key={goal.id} goal={goal} onAnswered={onAnswered} />
      ))}
    </div>
  );
};
