export default store => {
  store.on("@init", () => ({
    auth: {
      user: {},
      group: {}
    }
  }));

  store.on("auth/setUser", ({ auth }, user) => {
    return { auth: { ...auth, user } };
  });

  store.on("auth/setGroup", ({ auth }, group) => {
    return { auth: { ...auth, group } };
  });
};
