import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useStoreon } from "storeon/react";
import "date-fns";

import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  margin: {
    display: "block",
    margin: theme.spacing(2),
  },
  marginTopBottom: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

export default ({ goalParam = {}, setGoalData, setOpenDialog }) => {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  let inTwoWeeks = new Date();
  now.setHours(0, 0, 0, 0);
  inTwoWeeks = new Date(inTwoWeeks.setDate(now.getDate() + 7));
  const weekNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const classes = useStyles();
  const inputLabel = useRef(null);
  const { dispatch } = useStoreon();
  const [goal, setGoal] = useState({
    title: goalParam.title || "",
    description: goalParam.description || "",
    target: goalParam.target || "daily", // daily, weekly or a specific data
    targetDate: now.toISOString(),
    weekdays: goalParam.weekdays || [],
    offset: goalParam.offset || 0,
  });
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const createSubmit = (e) => {
    e.preventDefault();
    dispatch("ui/setLoading", true);
    if (!goal.title || !goal.target) {
      dispatch("error/setError", {
        visible: true,
        text: `Please, fill in the required fields.`,
      });
      dispatch("ui/setLoading", false);
      return false;
    }
    // just call return function to update resolution.
    dispatch("ui/setLoading", false);
    setGoalData(goal);
    setOpenDialog(false);
  };

  const changeWeekdays = (day, add) => {
    if (add && !goal.weekdays.includes(day)) {
      let newDays = [...goal.weekdays, day];
      newDays.sort();
      setGoal({ ...goal, weekdays: newDays });
    }

    if (!add) {
      setGoal({ ...goal, weekdays: goal.weekdays.filter((d) => d !== day) });
    }
  };

  return (
    <div className={classes.margin}>
      <form
        className={classes.marginTopBottom}
        noValidate
        onSubmit={createSubmit}
      >
        <p style={{ textAlign: "left", marginBottom: -10 }}>
          Examples:{" "}
          <em>
            Do 10 push-ups every day, Go running every Wednesday, Research
            tatttoo studios this Tuesday
          </em>
        </p>
        <TextField
          value={goal.title}
          onChange={(e) => setGoal({ ...goal, title: e.target.value })}
          className={classes.marginTopBottom}
          id="input-goal-title"
          variant="outlined"
          autoFocus={true}
          fullWidth
          label="Goal title"
          color="secondary"
        />
        <TextField
          value={goal.description}
          onChange={(e) => setGoal({ ...goal, description: e.target.value })}
          className={classes.marginTopBottom}
          id="input-goal-description"
          variant="outlined"
          fullWidth
          label="Goal description"
          color="secondary"
          multiline={true}
        />
        <FormControl
          variant="outlined"
          className={classes.marginTopBottom}
          fullWidth
        >
          <InputLabel
            ref={inputLabel}
            id="select-goal-target-label"
            color="secondary"
          >
            Goal frequency
          </InputLabel>
          <Select
            value={goal.target}
            onChange={(e) => setGoal({ ...goal, target: e.target.value })}
            labelId="select-goal-target-label"
            id="select-goal-target"
            color="secondary"
            labelWidth={labelWidth}
          >
            <MenuItem value="daily">Daily</MenuItem>
            <MenuItem value="weekly">Weekly</MenuItem>
            <MenuItem value="once">Specific Date</MenuItem>
          </Select>
        </FormControl>

        {goal && goal.target === "weekly" && (
          <div>
            {[0, 1, 2, 3, 4, 5, 6].map((day) => (
              <FormControlLabel
                key={`chk_${day}`}
                control={
                  <Checkbox
                    checked={goal.weekdays && goal.weekdays.includes(day)}
                    onChange={(e) => changeWeekdays(day, e.target.checked)}
                    value="primary"
                  />
                }
                label={weekNames[day]}
                labelPlacement="bottom"
              />
            ))}
          </div>
        )}

        {goal && goal.target === "once" && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid
              container
              justify="space-around"
              className={classes.marginTopBottom}
            >
              <DatePicker
                value={goal.targetDate}
                onChange={(e) => setGoal({ ...goal, targetDate: e })}
                margin="normal"
                id="date-picker-goal-start"
                label="Goal Target Date"
                format="EEE, d MMMM yyyy"
                color="secondary"
                minDate={now}
                maxDate={inTwoWeeks}
                autoOk={true}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        )}

        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.marginTopBottom}
        >
          Create goal
        </Button>
      </form>
    </div>
  );
};
